import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useSnackbar } from 'notistack';

import Layout from '../../components/Layout';
import Loading from '../../components/Loading';
import ListLayout from '../../components/ListLayout';
import useParticipant from '../../hooks/useParticipant';
import useParticipantPost from '../../hooks/useParticipantPost';
import useAppContext from '../../hooks/useAppContext';

const WinnersList = () => {
  const navigate = useNavigate();
  const { campaignId, userId } = useParams();
  const { participants } = useParticipant(campaignId);
  const { campaign, usersPostsWithScore } = useParticipantPost({
    campaignId,
    userId,
  });
  const {
    campaignState: { chooseWinners },
    smartContractState: { getCurrencyBalance, getNFTBalance, setWinners },
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [holderWinnerIds, setHolderWinnerIds] = useState([]);
  const [nonHolderWinnerIds, setNonHolderWinnerIds] = useState([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  // step 1: holders, step 2: non-holders
  const [isHoldersStep, setIsHoldersStep] = useState(true);
  const [holdersStatusMap, setHoldersStatusMap] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    loadUsersHolderStatus();
  }, [participants]);

  const renderedUsers = useMemo(() => {
    const start = page * limit;
    const end = (page + 1) * limit;

    return usersPostsWithScore
      .filter(({ userId }) => holdersStatusMap[userId] === isHoldersStep)
      .slice(start, end);
  }, [usersPostsWithScore, holdersStatusMap, isHoldersStep, page, limit]);

  const loadUsersHolderStatus = async () => {
    const holdersStatus = {};
    const promises = participants.map(async({ userId }) => {
        const balance = await getCurrencyBalance(userId);
        const nftBalance = await getNFTBalance(userId);
        return {userId, balance, nftBalance};
      }
    );
    const users = await Promise.all(promises);
    for (let user of users) {
      holdersStatus[user.userId] = user.balance >= 800000 || user.nftBalance >= 3;
    }

    setHoldersStatusMap(holdersStatus);
  };

  const columns = [
    {
      field: 'posts',
      headerName: 'Post Links',
      sortable: false,
      renderCell: (params) =>
        params.value.map((post) => (
          <IconButton key={post.id} onClick={() => window.open(post.url)}>
            {post.platform === 'Instagram' ? (
              <InstagramIcon htmlColor="#d62976" />
            ) : (
              <TwitterIcon htmlColor="#00acee" />
            )}
          </IconButton>
        )),
    },
    {
      field: 'instagramLikeCount',
      headerName: 'IG Likes',
      renderCell: (params) => params.value?.toLocaleString() ?? '-',
    },
    {
      field: 'instagramCommentCount',
      headerName: 'IG Comments',
      renderCell: (params) => params.value?.toLocaleString() ?? '-',
    },
    {
      field: 'twitterLikeCount',
      headerName: 'TW Likes',
      renderCell: (params) => params.value?.toLocaleString() ?? '-',
    },
    {
      field: 'twitterReplyCount',
      headerName: 'TW Replies',
      renderCell: (params) => params.value?.toLocaleString() ?? '-',
    },
    {
      field: 'twitterRetweetCount',
      headerName: 'TW Retweets',
      renderCell: (params) => params.value?.toLocaleString() ?? '-',
    },
    {
      field: 'score',
      headerName: 'Total Score',
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <Typography fontWeight="bold">
          {params.value.toLocaleString()}
        </Typography>
      ),
    },
    {
      field: 'userId',
      headerName: 'User Address',
      sortable: false,
      flex: 1,
    },
  ];

  const submitWinners = async () => {
    if (!campaign) return;
    onCloseDialog();
    setIsLoading(true);
    try {
      const holderScores = [];
      const nonHolderScores = [];
      for (let holderId of holderWinnerIds) {
        const user = usersPostsWithScore.find(
          ({ userId }) => userId === holderId
        );
        holderScores.push(user.score);
      }
      for (let nonHolderId of nonHolderWinnerIds) {
        const user = usersPostsWithScore.find(
          ({ userId }) => userId === nonHolderId
        );
        nonHolderScores.push(user.score);
      }
      await chooseWinners({
        campaignId,
        holderWinnerIds,
        nonHolderWinnerIds,
        holderScores,
        nonHolderScores,
        campaign,
      });
      await setWinners({
        campaignId,
        holderWinnerIds,
        nonHolderWinnerIds,
        holderScores,
        nonHolderScores,
      });
      enqueueSnackbar(
        `Confirmed ${holderWinnerIds.length} winners for campaign '${campaign.name}'`,
        { variant: 'success' }
      );
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setIsLoading(false);
    navigate('/campaigns');
  };

  const onCloseDialog = () => {
    setIsConfirmDialogOpen(false);
  };

  if (!campaign) return null;

  return (
    <Layout>
      <Loading isLoading={isLoading} />
      <ListLayout>
        <Dialog open={isConfirmDialogOpen} onClose={onCloseDialog}>
          <DialogTitle>Submit winners?</DialogTitle>
          <DialogContent>
            <Typography>
              $MIRL holders: {campaign?.holdersBounty?.toLocaleString()} $MIRL
              total for {holderWinnerIds.length} winner
              {holderWinnerIds.length > 1 ? 's' : ''}.
            </Typography>
            <Typography>
              Non-holders: {campaign?.nonHoldersBounty?.toLocaleString()} $MIRL
              total for {nonHolderWinnerIds.length} winner
              {nonHolderWinnerIds.length > 1 ? 's' : ''}.
            </Typography>
          </DialogContent>
          <DialogContent>
            <DialogContentText sx={{ fontSize: 14 }}>
              Reward cap for each winner is{' '}
              {campaign?.maxRewardPerWinner?.toLocaleString()} $MIRL. All
              unrewarded tokens will be returned to you (admin). <br />
              After confirmation, results cannot be reversed.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ fontWeight: 600, fontSize: 14, color: 'grey' }}
              onClick={onCloseDialog}
            >
              No, go back
            </Button>
            <Button
              sx={{ fontWeight: 600, fontSize: 14 }}
              onClick={submitWinners}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <IconButton onClick={() => navigate(`/campaigns`)}>
                <ArrowBackIcon />
              </IconButton>
              <Typography>
                Select winners for campaign '{campaign?.name}':{' '}
                {holderWinnerIds.length} holders, {nonHolderWinnerIds.length}{' '}
                non-holders selected.
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Typography fontWeight="bold">
                {isHoldersStep ? '$MIRL token holders' : 'Non-holders'}
              </Typography>
              <Typography>
                {isHoldersStep
                  ? holderWinnerIds.length
                  : nonHolderWinnerIds.length}{' '}
                winners selected
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={1}>
              {!isHoldersStep && (
                <Button onClick={() => setIsHoldersStep(true)}>
                  Back to holders
                </Button>
              )}

              <Button
                variant="contained"
                disabled={
                  (!isHoldersStep &&
                    holderWinnerIds.length + nonHolderWinnerIds.length === 0) ||
                  campaign?.status === 'Closed'
                }
                onClick={() => {
                  if (isHoldersStep) setIsHoldersStep(false);
                  else setIsConfirmDialogOpen(true);
                }}
              >
                {campaign?.status === 'Closed'
                  ? 'Winners already selected'
                  : isHoldersStep
                  ? 'Save & Next'
                  : 'Confirm Winners'}
              </Button>
            </Box>
          </Box>
          <Box
            height="500px"
            sx={{
              '& .MuiDataGrid-columnHeader--moving': {
                backgroundColor: 'white !important',
              },
            }}
          >
            <DataGrid
              columnVisibilityModel={{
                id: false,
              }}
              checkboxSelection
              disableColumnFilter
              disableColumnMenu
              rows={renderedUsers}
              columns={columns}
              rowCount={usersPostsWithScore.length}
              loading={!holdersStatusMap}
              rowsPerPageOptions={[20, 50]}
              pagination
              page={page}
              pageSize={limit}
              onPageChange={setPage}
              onPageSizeChange={setLimit}
              onSelectionModelChange={(data) => {
                if (isHoldersStep) setHolderWinnerIds(data);
                else setNonHolderWinnerIds(data);
              }}
              selectionModel={
                isHoldersStep ? holderWinnerIds : nonHolderWinnerIds
              }
              isRowSelectable={() => campaign?.status !== 'Closed'}
            />
          </Box>
          <Box display="flex"></Box>
        </Box>
      </ListLayout>
    </Layout>
  );
};

export default WinnersList;
