import { Routes, Route, Navigate } from 'react-router-dom';

import CampaignList from '../pages/Campaign';
import CampaignDetail from '../pages/Campaign/CampaignDetail';
import ParticipantList from '../pages/Campaign/ParticipantList';
import WinnersList from '../pages/Campaign/WinnersList';
import PostList from '../pages/Campaign/PostList';

const CampaignRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<CampaignList />} />
      <Route path="/create" element={<CampaignDetail />} />
      <Route path="/:id" element={<CampaignDetail />} />
      <Route path="/:campaignId/winners" element={<WinnersList />} />
      <Route path="/:id/participants" element={<ParticipantList />} />
      <Route
        path="/:campaignId/participants/:userId/posts"
        element={<PostList />}
      />
      <Route path="*" element={<Navigate to="/campaigns" replace />} />
    </Routes>
  );
};

export default CampaignRoute;
