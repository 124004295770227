import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Button, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StarIcon from '@mui/icons-material/Star';

import Layout from '../../components/Layout';
import ListLayout from '../../components/ListLayout';
import useParticipant from '../../hooks/useParticipant';
import useAppContext from '../../hooks/useAppContext';

const ParticipantList = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { participants: rawParticipants } = useParticipant(id);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [isWinnersLoaded, setIsWinnersLoaded] = useState(false);
  const [winners, setWinners] = useState([]);
  const {
    campaignState: { campaigns },
  } = useAppContext();

  const campaign = useMemo(
    () => campaigns.find((item) => item.id === id),
    [campaigns, id]
  );

  const participants = useMemo(
    () =>
      rawParticipants
        .map((participant) => ({
          ...participant,
          isWinner: winners.includes(participant.userId),
        }))
        .sort((a, b) => b.isWinner - a.isWinner),
    [rawParticipants, winners]
  );

  const renderedParticipants = useMemo(() => {
    const start = page * limit;
    const end = (page + 1) * limit;
    return participants.slice(start, end);
  }, [participants, page, limit]);

  useEffect(() => {
    // only load winners once to prevent rerenders from `campaigns`
    if (campaign.isDoneChoosingWinners && !isWinnersLoaded) {
      setWinners([...campaign.holderWinners, campaign.nonHolderWinners]);
      setIsWinnersLoaded(true);
    }
  }, [isWinnersLoaded, campaign]);

  const columns = [
    { field: 'id', hidden: true, sortable: false },
    {
      field: 'userId',
      headerName: 'User',
      sortable: false,
      flex: 1,
    },
    ...(winners.length > 0
      ? [
          {
            field: 'isWinner',
            headerName: 'Is Winner',
            sortable: false,
            renderCell: (params) =>
              params.value && (
                <Tooltip arrow title="This person is a campaign winner">
                  <StarIcon htmlColor="#ffbf01" />
                </Tooltip>
              ),
          },
        ]
      : []),
    {
      field: '',
      headerName: 'Actions',
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            sx={{ textTransform: 'none' }}
            onClick={() =>
              navigate(
                `/campaigns/${id}/participants/${params.row.userId}/posts`
              )
            }
          >
            View posts
          </Button>
        );
      },
    },
  ];

  return (
    <Layout>
      <ListLayout>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="center" gap={2}>
            <IconButton onClick={() => navigate(`/campaigns/${id}`)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography>Campaign participant list</Typography>
          </Box>
          <Box
            height="500px"
            sx={{
              '& .MuiDataGrid-columnHeader--moving': {
                backgroundColor: 'white !important',
              },
            }}
          >
            <DataGrid
              columnVisibilityModel={{
                id: false,
              }}
              disableColumnFilter
              disableColumnMenu
              rows={renderedParticipants}
              columns={columns}
              rowCount={participants.length}
              // loading={isLoading}
              rowsPerPageOptions={[20, 50]}
              pagination
              page={page}
              pageSize={limit}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            />
          </Box>
        </Box>
      </ListLayout>
    </Layout>
  );
};

export default ParticipantList;
