import { Routes, Route, Navigate } from 'react-router-dom';

import UserList from '../pages/User/UserList';
import UserDetail from '../pages/User/UserDetail';

const UserRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<UserList />} />
      <Route path="/:id" element={<UserDetail />} />
      <Route path="*" element={<Navigate to="/users" replace />} />
    </Routes>
  );
};

export default UserRoute;
