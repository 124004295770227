import { createContext } from 'react';

import useCampaign from '../hooks/useCampaign';
import useUser from '../hooks/useUser';
import useReport from '../hooks/useReport';
import useWalletModal from '../hooks/useWalletModal';
import useResponsive from '../hooks/useResponsive';
import useAccount from '../hooks/useAccount';
import useSmartContract from '../hooks/useSmartContract';
import useAdmin from '../hooks/useAdmin';

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  // split to one state for each hook
  // then dont need to update this file when a hook change
  const walletModalState = useWalletModal();
  const responsiveState = useResponsive();
  const adminState = useAdmin();
  const accountState = useAccount(walletModalState);
  const smartContractState = useSmartContract(accountState.account);
  const campaignState = useCampaign();
  const userState = useUser();
  const reportState = useReport();

  return (
    <AppContext.Provider
      value={{
        walletModalState,
        responsiveState,
        accountState,
        smartContractState,
        campaignState,
        userState,
        reportState,
        adminState,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
