import { Box, TextField } from '@mui/material';

import FieldLabel from './FieldLabel';

const Input = ({ label, error, helperText, ...props }) => {
  return (
    <Box>
      <FieldLabel>{label}</FieldLabel>
      <TextField
        fullWidth
        error={Boolean(error)}
        helperText={helperText ?? ' '}
        {...props}
      />
    </Box>
  );
};

export default Input;
