import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import BadCredentialRoute from './BadCredentialRoute';

import useAppContext from '../hooks/useAppContext';

const Navigations = () => {
  const {
    accountState: { isInitializedWeb3, isInitializedFirebase, user, account },
    adminState: { isInitialized: isInitializedAdmin, admins },
  } = useAppContext();

  const isInitialized =
    isInitializedAdmin && isInitializedWeb3 && isInitializedFirebase;

  if (!isInitialized) return null;

  if (!user) return <AuthRoutes />;

  if (
    !admins.includes(account) ||
    (admins.includes(account) && account !== user.uid)
  )
    return <BadCredentialRoute />;

  return <MainRoutes />;
};

export default Navigations;
