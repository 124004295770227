import { useEffect } from 'react';
import { Box } from '@mui/material';

import Header from './Header';
import { ConnectWalletContainer, ConnectWalletModal } from './ConnectWallet';

const Layout = ({ children }) => {
  /**
   * remove scroll-to-change from number inputs
   */
  useEffect(() => {
    const noScroll = function (event) {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };

    document.addEventListener('wheel', noScroll);

    return () => document.removeEventListener('wheel', noScroll);
  }, []);

  return (
    <Box bgcolor="#0c0626" height="100vh" display="flex" flexDirection="column">
      <Header />
      <Box mb={2} px={10}>
        <ConnectWalletContainer />
        <ConnectWalletModal />
      </Box>
      <Box flex={1} height="calc(100vh - 156.5px)" px={10}>
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
