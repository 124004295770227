import { Box } from '@mui/material';

import Layout from '../../components/Layout';
import ListLayout from '../../components/ListLayout';

const UserDetail = () => {
  return (
    <Layout>
      <ListLayout>UserDetail admin</ListLayout>
    </Layout>
  );
};

export default UserDetail;
