import { useState, useEffect, useMemo } from 'react';
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  Timestamp,
} from 'firebase/firestore';
import uniqBy from 'lodash/uniqBy';

import { firestore } from '../configs/firebase.config';

const instagramPostLink =
  /^https:\/\/(?:www\.)?instagram\.com\/(p|reel)\/([^/?#&]+).*$/g;
const twitterPostLink =
  /^https:\/\/twitter.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)([^/?#&]+).*$/g;

const useParticipantPost = ({ campaignId, userId }) => {
  const [campaign, setCampaign] = useState(null);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    let unsubscribeCampaign, unsubscribePosts;

    if (campaignId) {
      const q = userId
        ? query(
            collection(firestore, 'posts'),
            where('userId', '==', userId),
            where('campaignId', '==', campaignId)
          )
        : query(
            collection(firestore, 'posts'),
            where('campaignId', '==', campaignId)
          );

      unsubscribeCampaign = onSnapshot(
        doc(firestore, 'campaigns', campaignId),
        (snapshot) => {
          const data = snapshot.data();
          const status =
            data.startTime > Timestamp.now()
              ? 'Queued'
              : data.endTime > Timestamp.now()
              ? 'Ongoing'
              : !data.isDoneChoosingWinners
              ? 'Ended'
              : 'Closed';

          setCampaign({
            ...data,
            id: doc.id,
            status,
          });
        }
      );

      unsubscribePosts = onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          platform: doc.data().url.match(instagramPostLink)
            ? 'Instagram'
            : 'Twitter',
        }));
        setPosts(docs);
      });
    } else {
      setCampaign(null);
      setPosts([]);
    }

    return () => {
      unsubscribeCampaign && unsubscribeCampaign();
      unsubscribePosts && unsubscribePosts();
    };
  }, [campaignId, userId]);

  const usersPostsWithScore = useMemo(() => {
    const formulaWeights = campaign?.formulaWeights;

    const withScore = posts.map((post) => {
      let score = 0;
      if (!post.metrics || !formulaWeights) return { ...post, score };

      if (post.url.match(instagramPostLink) && formulaWeights.instagram) {
        const { likeCount, commentCount } = post.metrics;
        const { main, likes, comments } = formulaWeights.instagram;
        const instagramScore =
          (likeCount * likes + commentCount * comments) * main;
        score += instagramScore;
      }

      if (post.url.match(twitterPostLink) && formulaWeights.twitter) {
        const { likeCount, replyCount, retweetCount } = post.metrics;
        const { main, likes, replies, retweets } = formulaWeights.twitter;
        const twitterScore =
          (likeCount * likes + replyCount * replies + retweetCount * retweets) *
          main;
        score += twitterScore;
      }

      return { ...post, score };
    });

    const highestScorePostsFirst = withScore.sort((a, b) => b.score - a.score);

    const userPostsWithScore = uniqBy(
      highestScorePostsFirst,
      (post) => post.userId + post.platform // users can have max 1 post per platform
    );

    // reduce into user records instead of posts
    const usersMap = new Map();
    userPostsWithScore.forEach(function (post) {
      let previous;

      // Have we seen this label before?
      if (usersMap.has(post.userId)) {
        // Yes, grab it and add this data to it
        previous = usersMap.get(post.userId);
        previous.score += post.score;
        previous.posts.push({ ...post });

        // Don't keep this post, we've merged it into the previous one
        return false;
      }

      const postData = { score: post.score, posts: [post] };
      if (post.url.match(instagramPostLink)) {
        postData.instagramLikeCount = post.metrics?.likeCount ?? 0;
        postData.instagramCommentCount = post.metrics?.commentCount ?? 0;
      } else if (post.url.match(twitterPostLink)) {
        postData.twitterLikeCount = post.metrics?.likeCount ?? 0;
        postData.twitterReplyCount = post.metrics?.replyCount ?? 0;
        postData.twitterRetweetCount = post.metrics?.retweetCount ?? 0;
      }

      // Remember that we've seen it
      usersMap.set(post.userId, postData);

      // Keep this one, we'll merge any others that match into it
      return true;
    });

    const users = Array.from(
      usersMap,
      ([
        id,
        {
          score,
          posts,
          instagramLikeCount,
          instagramCommentCount,
          twitterLikeCount,
          twitterReplyCount,
          twitterRetweetCount,
        },
      ]) => ({
        id,
        userId: id,
        score,
        posts,
        instagramLikeCount,
        instagramCommentCount,
        twitterLikeCount,
        twitterReplyCount,
        twitterRetweetCount,
      })
    );

    return users.sort((a, b) => b.score - a.score);
  }, [posts, campaign?.formulaWeights]);

  return { posts, campaign, usersPostsWithScore };
};

export default useParticipantPost;
