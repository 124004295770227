import { Routes, Route, Navigate } from 'react-router-dom';

import ReportList from '../pages/Report/ReportList';
import ReportDetail from '../pages/Report/ReportDetail';

const ReportRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<ReportList />} />
      <Route path="/:id" element={<ReportDetail />} />
      <Route path="*" element={<Navigate to="/reports" replace />} />
    </Routes>
  );
};

export default ReportRoute;
