import { useState, useEffect } from 'react';
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  doc,
  setDoc,
} from 'firebase/firestore';

import { firestore } from '../configs/firebase.config';

const useReport = () => {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    let unsubscribe;

    const q = query(
      collection(firestore, 'reports'),
      where('status', '==', 'active'),
      orderBy('createdAt', 'desc')
    );
    unsubscribe = onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setReports(docs);
    });

    return () => unsubscribe && unsubscribe();
  }, []);

  const archiveReport = async (id) => {
    const reportRef = doc(firestore, 'reports', id);
    await setDoc(reportRef, { status: 'archived' }, { merge: true });
  };

  return { reports, archiveReport };
};

export default useReport;
