import { Box, Typography } from '@mui/material';

import Layout from '../../components/Layout';

const BadCredential = () => {
  return (
    <Layout>
      <Box
        height="100%"
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        p={2}
      >
        <Typography color="white">
          Your account is not authorized to visit this site.
        </Typography>
      </Box>
    </Layout>
  );
};

export default BadCredential;
