import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';

import Loading from '../../components/Loading';
import ActionButtons from '../../components/ActionButtons';
import useAppContext from '../../hooks/useAppContext';
import { formatTime } from '../../utils/dateTime';

const CampaignList = ({ index }) => {
  const navigate = useNavigate();
  const {
    campaignState: {
      campaigns: allCampaigns,
      activeCampaigns,
      endedCampaigns,
      draftCampaigns,
      removeCampaign,
    },
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [removeItem, setRemoveItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const campaigns = useMemo(
    () =>
      [allCampaigns, activeCampaigns, endedCampaigns, draftCampaigns][index],
    [[allCampaigns, activeCampaigns, endedCampaigns, draftCampaigns][index]]
  );

  const renderedCampaigns = useMemo(() => {
    const start = page * limit;
    const end = (page + 1) * limit;
    return campaigns.slice(start, end);
  }, [campaigns, page, limit]);

  const remove = async (id) => {
    setIsLoading(true);
    try {
      await removeCampaign(id);
      setRemoveItem(null);
      enqueueSnackbar('Removed campaign', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setIsLoading(false);
  };

  const columns = [
    { field: 'id', hidden: true, sortable: false },
    { field: 'name', headerName: 'Name', width: 150, sortable: false, flex: 1 },
    {
      field: 'holdersBounty',
      headerName: 'Bounty (holders)',
      width: 120,
      renderCell: (params) => (
        <Box flex="1" textAlign="right">
          {params.value.toLocaleString()}
        </Box>
      ),
    },
    {
      field: 'nonHoldersBounty',
      headerName: 'Bounty (non-holders)',
      width: 120,
      renderCell: (params) => (
        <Box flex="1" textAlign="right">
          {params.value.toLocaleString()}
        </Box>
      ),
    },
    ...(index !== 3 // not draft campaigns
      ? [
          { field: 'status', headerName: 'Status' },
          { field: 'participantsCount', headerName: 'Participants' },
        ]
      : []),
    {
      field: 'startTime',
      headerName: 'Start time',
      width: 150,
      renderCell: (params) => formatTime(params.row.startTime, true),
    },
    {
      field: 'endTime',
      headerName: 'End time',
      width: 150,
      renderCell: (params) => formatTime(params.row.endTime, true),
    },
    {
      field: '',
      headerName: 'Actions',
      width: 170,
      sortable: false,
      renderCell: (params) => {
        const isEndedCampaignsTab = index === 2;
        return (
          <ActionButtons
            editText={isEndedCampaignsTab ? 'Winners' : 'Edit'}
            onEdit={() => {
              // ended campaigns
              if (isEndedCampaignsTab)
                navigate(`/campaigns/${params.row.id}/winners`);
              else navigate(`/campaigns/${params.row.id}`);
            }}
            {...(params.row.status === 'Draft' && {
              onRemove: () => setRemoveItem(params.row),
            })}
          />
        );
      },
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} />
      {!!removeItem && (
        <Dialog open onClose={() => setRemoveItem(null)}>
          <DialogTitle>Do you want to remove this campaign?</DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontSize: 14, fontStyle: 'italic', color: 'tomato' }}
            >
              You are going to remove campaign {removeItem.name}.
            </DialogContentText>
            <DialogContentText
              sx={{ fontSize: 14, fontStyle: 'italic', color: 'tomato' }}
            >
              This action cannot be undone!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ fontWeight: 600, fontSize: 14 }}
              onClick={() => setRemoveItem(null)}
            >
              Cancel
            </Button>
            <Button
              color="error"
              sx={{ fontWeight: 600, fontSize: 14 }}
              onClick={() => remove(removeItem.id)}
              autoFocus
            >
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box
        height="100%"
        sx={{
          '& .MuiDataGrid-columnHeader--moving': {
            backgroundColor: 'white !important',
          },
        }}
      >
        <DataGrid
          columnVisibilityModel={{
            id: false,
          }}
          disableColumnFilter
          disableColumnMenu
          rows={renderedCampaigns}
          columns={columns}
          rowCount={campaigns.length}
          // loading={isLoading}
          rowsPerPageOptions={[20, 50]}
          pagination
          page={page}
          pageSize={limit}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
        />
      </Box>
    </>
  );
};

export default CampaignList;
