import { useState, useEffect } from 'react';
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
} from 'firebase/firestore';

import { firestore } from '../configs/firebase.config';

const useParticipant = (campaignId) => {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    let unsubscribe;

    if (campaignId) {
      const q = query(
        collection(firestore, 'participants'),
        where('campaignId', '==', campaignId),
        orderBy('createdAt', 'desc')
      );

      unsubscribe = onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setParticipants(docs);
      });
    } else {
      setParticipants([]);
    }

    return () => unsubscribe && unsubscribe();
  }, [campaignId]);

  return {
    participants,
  };
};

export default useParticipant;
