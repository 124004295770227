import { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';

import { firestore } from '../configs/firebase.config';

const useUser = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    let unsubscribe;

    const q = query(
      collection(firestore, 'users'),
      orderBy('createdAt', 'desc')
    );
    unsubscribe = onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(docs);
    });

    return () => unsubscribe && unsubscribe();
  }, []);

  return { users };
};

export default useUser;
