import { Routes, Route, Navigate } from 'react-router-dom';

import BadCredential from '../pages/BadCredential';

const BadCredentialRoute = () => {
  return (
    <Routes>
      <Route path="/403" element={<BadCredential />} />
      <Route path="*" element={<Navigate to="/403" replace />} />
    </Routes>
  );
};

export default BadCredentialRoute;
