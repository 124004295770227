import { useState, useEffect } from 'react';
import { collection, query, onSnapshot } from 'firebase/firestore';

import { firestore } from '../configs/firebase.config';

const useAdmin = () => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    let unsubscribe;

    const q = query(collection(firestore, 'admins'));
    unsubscribe = onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs.map((doc) => doc.id);
      setAdmins(docs);
      setIsInitialized(true);
    });

    return () => unsubscribe && unsubscribe();
  }, []);

  return { isInitialized, admins };
};

export default useAdmin;
