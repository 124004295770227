import { Grid, Box } from '@mui/material';

import Sidebar from './Sidebar';

const ListLayout = ({ children }) => {
  return (
    <Box
      px={2}
      // pt={0}
      // flex={1}
      display="flex"
      flexDirection="column"
      height="calc(100vh - 176.5px)"
    >
      <Box
        sx={{
          height: '100%',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid container spacing={2} flex={1} sx={{ maxHeight: '100%' }}>
          <Grid item xs={3} sm={3} sx={{ maxHeight: '100%' }}>
            <Box
              height="100%"
              borderRadius={2}
              overflow="hidden"
              bgcolor="white"
            >
              <Sidebar />
            </Box>
          </Grid>
          <Grid item xs={9} sm={9} sx={{ maxHeight: '100%' }}>
            <Box
              display="flex"
              flexDirection="column"
              p={2}
              height="100%"
              borderRadius={2}
              overflow="auto"
              bgcolor="white"
            >
              {children}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ListLayout;
