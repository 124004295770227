import moment from 'moment';

export const formatTime = (timestamp, formatAsUTC) =>
  formatAsUTC
    ? moment(timestamp.toDate()).utc().format('DD/MM/YYYY HH:mm')
    : moment(timestamp.toDate()).format('DD/MM/YYYY HH:mm');

export const getTimeAsUTCDate = (millis) => {
  const dateInCurrentTimezone = new Date(millis);
  const sameTimeInUTCMillis = Date.UTC(
    dateInCurrentTimezone.getFullYear(),
    dateInCurrentTimezone.getMonth(),
    dateInCurrentTimezone.getDate(),
    dateInCurrentTimezone.getHours(),
    dateInCurrentTimezone.getMinutes(),
    dateInCurrentTimezone.getSeconds()
  );

  return new Date(sameTimeInUTCMillis);
};

export const getTimeAsLocaleDate = (date) => {
  const hoursOffset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() + hoursOffset * 60 * 1000);
  const offset = hoursOffset / 60;
  const hours = date.getHours();

  newDate.setHours(hours + offset);

  return newDate;
};
