import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Grid, Paper, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Layout from '../../components/Layout';
import ListLayout from '../../components/ListLayout';
import useParticipantPost from '../../hooks/useParticipantPost';

const PostList = () => {
  const navigate = useNavigate();
  const { campaignId, userId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { posts } = useParticipantPost({
    campaignId,
    userId,
  });

  return (
    <Layout>
      <ListLayout>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="center" gap={2}>
            <IconButton
              onClick={() => navigate(`/campaigns/${campaignId}/participants`)}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography>Campaign participant post list</Typography>
          </Box>
          <Box>
            <Grid container spacing={2}>
              {posts.map((post) => (
                <Grid key={post.id} item xs={12} sm={4} md={3}>
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={post.image}
                      style={{
                        width: '100%',
                        aspectRatio: '3/4',
                        objectFit: 'cover',
                        objectPosition: 'center',
                      }}
                    />
                    <Box p={1} display="flex" flexDirection="column" gap={1}>
                      <Typography
                        fontSize={13}
                        sx={{
                          overflow: 'hidden',
                          color: 'dodgerblue',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() => window.open(post.url)}
                      >
                        {post.url}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </ListLayout>
    </Layout>
  );
};

export default PostList;
