import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

const navs = [
  { name: 'Campaigns', path: '/campaigns' },
  // { name: 'Community', path: '/users' },
  { name: 'Reports', path: '/reports' },
];

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Box display="flex" flexDirection="column">
      {navs.map((nav) => (
        <Box
          key={nav.path}
          p={2}
          bgcolor={pathname === nav.path ? '#ddd' : 'transparent'}
          sx={{ cursor: 'pointer', '&:hover': { bgcolor: '#f2f2f2' } }}
          onClick={() => navigate(nav.path)}
        >
          <Typography>{nav.name}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Sidebar;
