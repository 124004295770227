import { Box } from '@mui/material';

import Layout from '../../components/Layout';
import ListLayout from '../../components/ListLayout';

const UserList = () => {
  return (
    <Layout>
      <ListLayout>UserList admin</ListLayout>
    </Layout>
  );
};

export default UserList;
