import useAppContext from '../hooks/useAppContext';

export const ConnectWalletContainer = () => {
  const {
    accountState: {
      account,
      user,
      isInitializedWeb3,
      isInitializedFirebase,
    },
    walletModalState: { openWalletModal },
  } = useAppContext();

  const renderContent = () => {
    if (!account || !user)
      return (
        <button
          className="wallet-btn header-wallet-btn"
          onClick={openWalletModal}
        >
          Connect Wallet
        </button>
      );

    return (
      <div style={{ display: 'flex', gap: 8 }}>
        <div>
          <div
            onClick={openWalletModal}
            className="wallet-btn header-wallet-btn"
          >
            <img
              src='/images/metamask.png'
              alt="wallet-icon"
              width={30}
              height={30}
            />
            {`${account.slice(0, 5)} ... ${account.slice(-5)}`}
          </div>
        </div>
      </div>
    );
  };

  if (!isInitializedWeb3 || !isInitializedFirebase) return null;

  return (
    <div className="container connect-wallet-container">{renderContent()}</div>
  );
};

export const ConnectWalletModal = () => {
  const {
    walletModalState: { isWalletModalOpen, closeWalletModal },
    accountState: {
      account,
      user,
      isInitializedWeb3,
      isInitializedFirebase,
      isAuthenticatingWeb3,
      isAuthenticatingFirebase,
      connectWallet,
      disconnectWallet,
    },
  } = useAppContext();

  if (!isWalletModalOpen) return null;

  if (!isInitializedWeb3 || !isInitializedFirebase) return null;

  const renderContent = () => {
    const isAuthenticating = isAuthenticatingWeb3 || isAuthenticatingFirebase;

    if (isAuthenticating)
      return (
        <p style={{ color: 'white' }}>Connecting your wallet. Please wait...</p>
      );

    if (!account || !user)
      return (
        <>
          <p style={{ color: 'white' }}>Connect your wallet!</p>
          <button
            disabled={isAuthenticating}
            className="wallet-btn"
            onClick={connectWallet}
          >
            <img
              src="/images/metamask.png"
              alt="Metamask icon"
              width={30}
              height={30}
            />
            Metamask
          </button>
        </>
      );

    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        <div
          style={{
            border: '1px solid green',
            borderRadius: 8,
            padding: 16,
            display: 'flex',
            justifyContent: 'center',
            gap: 16,
          }}
        >
          <img
            src='/images/metamask.png'
            alt="wallet-icon"
            width={20}
            height={20}
          />
          <span
            style={{ color: 'green', lineHeight: '1.75rem', fontWeight: 600 }}
          >
            {account}
          </span>
        </div>
        <button
          className="wallet-btn"
          style={{ alignSelf: 'center' }}
          onClick={disconnectWallet}
        >
          <span style={{ color: 'tomato' }}>Disconnect wallet</span>
        </button>
      </div>
    );
  };

  return (
    <div className="connect-wallet-modal" onClick={closeWalletModal}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="connect-wallet-modal-content"
      >
        {renderContent()}
      </div>
    </div>
  );
};
