import { Box, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';

import FieldLabel from './FieldLabel';
import useAppContext from '../hooks/useAppContext';

const HtmlInput = ({ label, value, onChange, error, helperText }) => {
  const {
    campaignState: { uploadImage },
  } = useAppContext();

  const onUploadImageFile = (callback, value, meta) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');

    input.onchange = function () {
      const file = this.files[0];

      const reader = new FileReader();
      reader.onload = async () => {
        const { url } = await uploadImage(file);
        callback(url, { title: file.name });
      };
      reader.readAsDataURL(file);
    };

    input.click();
  };

  return (
    <Box>
      <FieldLabel>{label}</FieldLabel>
      <Editor
        value={value || ''}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'anchor',
            'searchreplace',
            'code',
            'media',
            'table',
            'help',
            'wordcount',
          ],
          toolbar:
            'undo redo | image media | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'table | tableprops tablerowprops tablecellprops | ' +
            'code | removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          file_picker_types: 'image',
          automatic_uploads: true,
          file_picker_callback: onUploadImageFile,
        }}
        onEditorChange={onChange}
      />
      <Box height="16px">
        {error && (
          <Typography color="tomato" fontSize="0.8rem">
            {helperText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default HtmlInput;
//https://us-central1-mirl-w2e.cloudfunctions.net/socials
