import { useMemo, useState, useEffect } from 'react';
import {
  collection,
  query,
  orderBy,
  where,
  onSnapshot,
  doc,
  deleteDoc,
  addDoc,
  getDocs,
  serverTimestamp,
  Timestamp,
  updateDoc,
  getDoc,
  setDoc,
} from 'firebase/firestore';
import { uploadBytes, ref, getDownloadURL } from 'firebase/storage';
import { v4 } from 'uuid';

import { firestore, storage } from '../configs/firebase.config';
import { TXN_TYPE } from '../utils/constants';

const useCampaign = () => {
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    let unsubscribe;

    const q = query(
      collection(firestore, 'campaigns'),
      orderBy('createdAt', 'desc')
    );
    unsubscribe = onSnapshot(q, (snapshot) => {
      const docs = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        status: !doc.data().isPublic
          ? 'Draft'
          : doc.data().startTime > Timestamp.now()
          ? 'Queued'
          : doc.data().endTime > Timestamp.now()
          ? 'Ongoing'
          : !doc.data().isDoneChoosingWinners
          ? 'Ended'
          : 'Closed',
      }));
      setCampaigns(docs);
    });

    return () => unsubscribe && unsubscribe();
  }, []);

  const activeCampaigns = useMemo(
    () =>
      campaigns.filter(
        (campaign) =>
          campaign.status === 'Ongoing' || campaign.status === 'Queued'
      ),
    [campaigns]
  );

  const endedCampaigns = useMemo(
    () =>
      campaigns.filter(
        (campaign) =>
          campaign.status === 'Ended' || campaign.status === 'Closed'
      ),
    [campaigns]
  );

  const draftCampaigns = useMemo(
    () => campaigns.filter((campaign) => campaign.status === 'Draft'),
    [campaigns]
  );

  const removeCampaign = async (id) => {
    const campaignRef = doc(firestore, 'campaigns', id);
    await deleteDoc(campaignRef);
  };

  const createCampaign = async ({ id, ...data }) => {
    const campaignData = {
      participantsCount: 0,
      createdAt: serverTimestamp(),
      isDoneChoosingWinners: false,
      ...data,
    };

    // public
    if (id) await setDoc(doc(firestore, 'campaigns', id), campaignData);
    // draft
    else await addDoc(collection(firestore, 'campaigns'), campaignData);
  };

  const updateCampaign = async ({ id, ...data }) => {
    const docRef = doc(firestore, 'campaigns', id);
    await updateDoc(docRef, data);

    // update campaignEndTime in posts
    const campaign = campaigns.find((campaign) => campaign.id === id);
    const isEndTimeChanged = campaign.endTime !== data.endTime;
    if (isEndTimeChanged) {
      const campaignPostsQuery = query(
        collection(firestore, 'posts'),
        where('campaignId', '==', id)
      );

      const postDocs = await getDocs(campaignPostsQuery);
      const promises = [];
      postDocs.docs.forEach((document) =>
        promises.push(
          updateDoc(doc(firestore, 'posts', document.id), {
            campaignEndTime: data.endTime,
          })
        )
      );
      await Promise.all(promises);
    }
  };

  const uploadImage = async (file) => {
    const type = file.contentType || file.type;
    const storageRef = `campaigns/${v4()}-${file.name}`;
    const fileRef = ref(storage, storageRef);
    const metadata = { contentType: type };
    await uploadBytes(fileRef, file, metadata);
    const url = await getDownloadURL(fileRef);
    return { imageRef: storageRef, url };
  };

  const chooseWinners = async ({
    campaignId,
    campaign,
    holderWinnerIds,
    nonHolderWinnerIds,
    holderScores,
    nonHolderScores,
  }) => {
    // update campaign
    const docRef = doc(firestore, 'campaigns', campaignId);
    await updateDoc(docRef, {
      holderWinners: holderWinnerIds,
      nonHolderWinners: nonHolderWinnerIds,
      isDoneChoosingWinners: true,
    });
    const txnCollectionRef = collection(firestore, 'transactions');

    const promises = [];
    const totalHoldersScore = holderScores.reduce(
      (sum, score) => (sum += score),
      0
    );
    const totalNonHoldersScore = nonHolderScores.reduce(
      (sum, score) => (sum += score),
      0
    );

    holderWinnerIds.forEach((userId, index) => {
      const amount =
        (campaign.holdersBounty * holderScores[index]) / totalHoldersScore;
      // create transaction
      const transaction = {
        userId,
        campaignId,
        amount,
        campaignName: campaign.name,
        type: TXN_TYPE.rewards,
        createdAt: serverTimestamp(),
      };
      promises.push(addDoc(txnCollectionRef, transaction));

      // update user balance
      const userRef = doc(firestore, 'users', userId);
      promises.push(
        getDoc(userRef).then((user) =>
          updateDoc(userRef, {
            balance: user.data().balance + amount,
          })
        )
      );
    });
    nonHolderWinnerIds.forEach((userId, index) => {
      const amount =
        (campaign.holdersBounty * nonHolderScores[index]) /
        totalNonHoldersScore;
      // create transaction
      const transaction = {
        userId,
        campaignId,
        amount,
        campaignName: campaign.name,
        type: TXN_TYPE.rewards,
        createdAt: serverTimestamp(),
      };
      promises.push(addDoc(txnCollectionRef, transaction));

      // update user balance
      const userRef = doc(firestore, 'users', userId);
      promises.push(
        getDoc(userRef).then((user) =>
          updateDoc(userRef, {
            balance: user.data().balance + amount,
          })
        )
      );
    });

    await Promise.all(promises);
  };

  return {
    campaigns,
    activeCampaigns,
    endedCampaigns,
    draftCampaigns,
    removeCampaign,
    createCampaign,
    updateCampaign,
    chooseWinners,
    uploadImage,
  };
};

export default useCampaign;
