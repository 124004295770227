import { useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  IconButton,
  filledInputClasses,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';

import Layout from '../../components/Layout';
import Loading from '../../components/Loading';
import ListLayout from '../../components/ListLayout';
import ActionButtons from '../../components/ActionButtons';
import useAppContext from '../../hooks/useAppContext';

const ReportList = () => {
  const navigate = useNavigate();
  const {
    reportState: { reports, archiveReport },
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [isLoading, setIsLoading] = useState(filledInputClasses);

  const renderedReports = useMemo(() => {
    const start = page * limit;
    const end = (page + 1) * limit;
    return reports.slice(start, end);
  }, [reports, page, limit]);

  const archive = async (id) => {
    setIsLoading(true);

    try {
      await archiveReport(id);
      enqueueSnackbar('Archived report', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }

    setIsLoading(false);
  };

  const columns = [
    { field: 'id', hidden: true, sortable: false },
    { field: 'name', headerName: 'Name', sortable: false, flex: 1 },
    { field: 'email', headerName: 'Email', sortable: false, width: 200 },
    {
      field: 'userId',
      headerName: 'Wallet address',
      sortable: false,
      width: 300,
    },
    {
      field: '',
      headerName: 'Actions',
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <ActionButtons
            editText="View detail"
            removeText="Archived"
            onEdit={() => navigate(`/reports/${params.id}`)}
            onRemove={() => archive(params.id)}
          />
        );
      },
    },
  ];

  return (
    <Layout>
      <ListLayout>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography>Report list</Typography>
          </Box>
          <Box
            height="500px"
            sx={{
              '& .MuiDataGrid-columnHeader--moving': {
                backgroundColor: 'white !important',
              },
            }}
          >
            <DataGrid
              columnVisibilityModel={{
                id: false,
              }}
              disableColumnFilter
              disableColumnMenu
              rows={renderedReports}
              columns={columns}
              rowCount={reports.length}
              // loading={isLoading}
              rowsPerPageOptions={[20, 50]}
              pagination
              page={page}
              pageSize={limit}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
            />
          </Box>
        </Box>
      </ListLayout>
    </Layout>
  );
};

export default ReportList;
