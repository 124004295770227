import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Layout from '../../components/Layout';
import ListLayout from '../../components/ListLayout';
import useAppContext from '../../hooks/useAppContext';
import { formatTime } from '../../utils/dateTime';

const ReportDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    reportState: { reports },
    campaignState: { campaigns },
  } = useAppContext();

  const report = reports.find((item) => item.id === id);

  if (!report) return null;

  const campaign = campaigns.find((item) => item.id === report.campaignId);

  const emailSubject = `Regarding your report against ${campaign?.name} | MIRL Wear2Earn`;
  const openInGmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${
    report.email
  }&su=${encodeURIComponent(emailSubject)}`;

  return (
    <Layout>
      <ListLayout>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="center" gap={2}>
            <IconButton onClick={() => navigate(`/reports`)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">Report detail</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Typography
                textAlign="right"
                fontStyle="italic"
                color={(theme) => theme.palette.grey[500]}
              >
                Wallet Address:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography>{report.userId}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                textAlign="right"
                fontStyle="italic"
                color={(theme) => theme.palette.grey[500]}
              >
                Reported by:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography>
                {report.name}{' '}
                <Tooltip arrow title={`Email ${report.email}`}>
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => window.open(openInGmailUrl)}
                  >
                    ({report.email})
                  </span>
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                textAlign="right"
                fontStyle="italic"
                color={(theme) => theme.palette.grey[500]}
              >
                Reported Campaign:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Tooltip arrow title="View campaign">
                <Box
                  display="flex"
                  alignItems="center"
                  gap={0.5}
                  width="fit-content"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => window.open(`/campaigns/${campaign.id}`)}
                >
                  <Typography sx={{ textDecoration: 'underline' }}>
                    {campaign?.name}
                  </Typography>
                  <OpenInNewIcon fontSize="0.5em" />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                textAlign="right"
                fontStyle="italic"
                color={(theme) => theme.palette.grey[500]}
              >
                Created at:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography>{formatTime(report.createdAt)}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography
                textAlign="right"
                fontStyle="italic"
                color={(theme) => theme.palette.grey[500]}
              >
                Message:
              </Typography>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography>{report.description}</Typography>
            </Grid>
          </Grid>
          <Box width="fit-content" mx="auto">
            <Button
              variant="contained"
              sx={{ width: 200 }}
              onClick={() => window.open(openInGmailUrl)}
            >
              Reply
            </Button>
          </Box>
        </Box>
      </ListLayout>
    </Layout>
  );
};

export default ReportDetail;
