import { Web3Provider } from '@ethersproject/providers';
import { Contract } from '@ethersproject/contracts';
import { parseUnits } from '@ethersproject/units';

import { toHexString } from '../utils/strings';
import environments from '../utils/environments';
import MIRLCampaignContract from '../assets/abis/MIRLCampaign.json';
import MIRLTokenContract from '../assets/abis/MadeInRealLife.json';
import MIRLNFTContract from '../assets/abis/MIRLNFT.json';
const {
  NETWORK_ID: networkId,
  CAMPAIGN_CONTRACT_ADDRESS,
  MIRL_CONTRACT_ADDRESS,
  MIRLNFT_CONTRACT_ADDRESS,
  CURRENCY_DECIMALS,
} = environments;

const MetamaskErrors = {
  RateLimit: 'Rate limit exceeded: 40 per 1 second',
  UserRejected: 'user rejected transaction',
};

const handleError = (err) => {
  const message = err.error?.data?.message
    ? err.error?.data?.message.replace('execution reverted: ', '')
    : err.message;

  let errMessage = message;
  if (errMessage.includes(MetamaskErrors.UserRejected)) {
    errMessage = 'Transaction is rejected';
  }

  if (errMessage.includes(MetamaskErrors.RateLimit)) {
    errMessage = 'Something is wrong. Please try again';
  }

  throw new Error(errMessage);
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const useSmartContract = () => {
  const checkNetwork = async () => {
    const { ethereum } = window || {};
    if (!ethereum) return;

    if (ethereum.chainId !== toHexString(networkId)) {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHexString(networkId) }],
      });
    }
  };

  const getSigner = () => {
    const { ethereum } = window || {};
    if (!ethereum) return null;

    const provider = new Web3Provider(ethereum);
    const signer = provider.getSigner();
    return signer;
  };

  const getCampaignContract = () => {
    const signer = getSigner();
    if (!signer) return null;

    const CampaignContract = new Contract(
      CAMPAIGN_CONTRACT_ADDRESS,
      MIRLCampaignContract.abi,
      signer
    );

    return CampaignContract;
  };

  const getCurrencyBalance = async (address) => {
    await checkNetwork();
    const signer = getSigner();
    if (!signer) return 0;

    const CurrencyContract = new Contract(
      MIRL_CONTRACT_ADDRESS,
      MIRLTokenContract.abi,
      signer
    );
    const res = await CurrencyContract.balanceOf(address);
    const balance = Number(res.toString()) / 10 ** CURRENCY_DECIMALS;
    return balance;
  };

  const getNFTBalance = async (address) => {
    await checkNetwork();
    const signer = getSigner();
    if (!signer) return 0;

    const NFTContract = new Contract(
      MIRLNFT_CONTRACT_ADDRESS,
      MIRLNFTContract.abi,
      signer
    );
    const res = await NFTContract.balanceOf(address);
    const balance = Number(res.toString());
    return balance;
  };
  

  const createCampaign = async (
    holdersBounty,
    nonHoldersBounty,
    maxRewardPerHolderWinner,
    maxRewardPerNonHolderWinner
  ) => {
    try {
      const holdersReward = parseUnits(
        holdersBounty.toString(),
        CURRENCY_DECIMALS
      );
      const nonHoldersReward = parseUnits(
        nonHoldersBounty.toString(),
        CURRENCY_DECIMALS
      );
      const maxRewardHolders = parseUnits(
        maxRewardPerHolderWinner.toString(),
        CURRENCY_DECIMALS
      );
      const maxRewardNonHolders = parseUnits(
        maxRewardPerNonHolderWinner.toString(),
        CURRENCY_DECIMALS
      );
      await checkNetwork();
      await delay(1000);
      
    const signer = getSigner();
    if (!signer) return 0;

    const CurrencyContract = new Contract(
      MIRL_CONTRACT_ADDRESS,
      MIRLTokenContract.abi,
      signer
    );

      const txApproval = await CurrencyContract?.approve(
        CAMPAIGN_CONTRACT_ADDRESS,
        holdersReward.add(nonHoldersReward)
      );
      const res = await txApproval.wait(); 
      if (res.status === 1) {

      console.log({holdersReward, nonHoldersReward,maxRewardHolders,maxRewardNonHolders});


      const CampaignContract = getCampaignContract();
      const tx = await CampaignContract?.createCampaign(
        holdersReward,
        nonHoldersReward,
        maxRewardHolders,
        maxRewardNonHolders
      );
      const txnReceipt = await tx.wait();
      const campaignId = txnReceipt.events[2].args.campaignId.toString();
      return campaignId;
      }
      
    } catch (err) {
      console.log(err);
      handleError(err);
    }
  };

  const setWinners = async (data) => {
    const {
      campaignId,
      holderWinnerIds,
      holderScores,
      nonHolderWinnerIds,
      nonHolderScores,
    } = data;

    await checkNetwork();
    await delay(1000);

    const CampaignContract = getCampaignContract();
    const tx = await CampaignContract?.setWinner(
      campaignId,
      holderWinnerIds,
      holderScores,
      nonHolderWinnerIds,
      nonHolderScores
    );
    const txnReceipt = await tx.wait();
    return txnReceipt;
  };

  return { getCurrencyBalance, getNFTBalance, createCampaign, setWinners };
};

export default useSmartContract;
