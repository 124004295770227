import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Tab, Tabs } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

import CampaignList from './CampaignList';
import Layout from '../../components/Layout';
import ListLayout from '../../components/ListLayout';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ flex: 1 }}
      role="tabpanel"
      hidden={value !== index}
      id={`campaigns-tabpanel-${index}`}
      aria-labelledby={`campaigns-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box height="100%" sx={{ pt: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (name) => ({
  id: `campaigns-tab-${name}`,
  'aria-controls': `campaigns-tabpanel-${name}`,
});

export default function CampaignTabs() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const statuses = ['All', 'Active', 'Ended', 'Draft'];

  return (
    <Layout>
      <ListLayout>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {statuses.map((status, index) => (
              <Tab
                key={index}
                label={`${status} Campaigns`}
                {...a11yProps(status)}
              />
            ))}
          </Tabs>
          <Box display="flex" alignItems="center">
            <Button
              size="small"
              variant="contained"
              color="success"
              sx={{ fontSize: 10, my: 'auto' }}
              onClick={() => navigate('/campaigns/create')}
            >
              Create
            </Button>
          </Box>
        </Box>
        {statuses.map((status, index) => (
          <TabPanel key={index} value={value} index={index}>
            <CampaignList index={index} />
          </TabPanel>
        ))}
      </ListLayout>
    </Layout>
  );
}
