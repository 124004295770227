import { Routes, Route, Navigate } from 'react-router-dom';

import CampaignRoute from './CampaignRoute';
import UserRoute from './UserRoute';
import ReportRoute from './ReportRoute';

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/campaigns/*" element={<CampaignRoute />} />
      <Route path="/users/*" element={<UserRoute />} />
      <Route path="/reports/*" element={<ReportRoute />} />
      <Route path="*" element={<Navigate to="/campaigns" replace />} />
    </Routes>
  );
};

export default MainRoutes;
