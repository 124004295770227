import { useState, useMemo, useId, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  TextField,
  Tooltip,
  InputAdornment,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Timestamp } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { useSnackbar } from 'notistack';
import 'react-datepicker/dist/react-datepicker.css';

import Layout from '../../components/Layout';
import Loading from '../../components/Loading';
import ListLayout from '../../components/ListLayout';
import FieldLabel from '../../components/FieldLabel';
import Input from '../../components/Input';
import HtmlInput from '../../components/HtmlInput';
import useAppContext from '../../hooks/useAppContext';
import { storage } from '../../configs/firebase.config';
import { getTimeAsUTCDate, getTimeAsLocaleDate } from '../../utils/dateTime';

const validationSchema = yup.object({
  name: yup.string().trim().required('Name is empty'),
  description: yup.string().trim().required('Description is empty'),
  holdersBounty: yup
    .number('Bounty must be a number')
    .required('Required')
    .min(0, 'Min is 0'),
  nonHoldersBounty: yup
    .number('Bounty must be a number')
    .required('Required')
    .min(0, 'Min is 0'),
  maxRewardPerHolderWinner: yup
    .number('Max reward must be a number')
    .required('Required')
    .min(0, 'Min is 0'),
  maxRewardPerNonHolderWinner: yup
    .number('Max reward must be a number')
    .required('Required')
    .min(0, 'Min is 0'),
  task: yup.string().trim().required('Task description text is required'),
  summary: yup.string().trim().required('Summary text is required'),
  winningCriteria: yup.string().required('Winning criteria is required'),
  startTime: yup
    .number('Start time is invalid')
    .min(0, 'Start time is invalid')
    .test(
      'before end time',
      'Start time must be before end time',
      function (value) {
        return this.parent.startTime < this.parent.endTime;
      }
    ),
  endTime: yup
    .number('End time is invalid')
    .min(0, 'End time is invalid')
    .test(
      'after start time',
      'Start time must be before end time',
      function (value) {
        return this.parent.startTime < this.parent.endTime;
      }
    ),
  instagram: yup.string().trim(),
  twitter: yup.string().trim(),
  tiktok: yup.string().trim(),
  website: yup.string().trim(),
  weightIG: yup.number().min(0, 'Min is 0'),
  weightIGLikes: yup.number().min(0, 'Min is 0'),
  weightIGComments: yup.number().min(0, 'Min is 0'),
  weightTW: yup.number().min(0, 'Min is 0'),
  weightTWLikes: yup.number().min(0, 'Min is 0'),
  weightTWReplies: yup.number().min(0, 'Min is 0'),
  weightTWRetweets: yup.number().min(0, 'Min is 0'),
});

const CampaignDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const inputId = useId();
  const labelRef = useRef();
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    campaignState: {
      campaigns,
      createCampaign,
      updateCampaign,
      removeCampaign,
      uploadImage,
    },
    smartContractState: { createCampaign: createCampaignWeb3 },
  } = useAppContext();

  const campaign = useMemo(
    () => campaigns.find((item) => item.id === id),
    [campaigns, id]
  );
  const [formulaPlatforms, setFormulaPlatforms] = useState({
    instagram: campaign?.formulaWeights?.instagram ?? true,
    twitter: campaign?.formulaWeights?.twitter ?? true,
    // tiktok: campaign?.formulaWeights?.tiktok ?? true,
  });

  const initialValues = useMemo(() => {
    if (campaign) {
      const {
        socials,
        participantsCount,
        startTime,
        endTime,
        formulaWeights,
        ...rest
      } = campaign;

      // remove unneeded values
      delete rest.id;
      delete rest.isPublic;

      return {
        ...rest,
        instagram: campaign?.instagram || '',
        twitter: campaign?.twitter || '',
        tiktok: campaign?.tiktok || '',
        website: campaign?.website || '',
        startTime: getTimeAsLocaleDate(startTime.toDate()).getTime(),
        endTime: getTimeAsLocaleDate(endTime.toDate()).getTime(),
        weightIG: formulaWeights?.instagram?.main,
        weightIGLikes: formulaWeights?.instagram?.likes,
        weightIGComments: formulaWeights?.instagram?.comments,
        weightTW: formulaWeights?.twitter?.main,
        weightTWLikes: formulaWeights?.twitter?.likes,
        weightTWReplies: formulaWeights?.twitter?.replies,
        weightTWRetweets: formulaWeights?.twitter?.retweets,
      };
    }

    return {
      name: '',
      description: '',
      holdersBounty: 0,
      nonHoldersBounty: 0,
      maxRewardPerHolderWinner: 0,
      maxRewardPerNonHolderWinner: 0,
      task: '',
      taskShort: '',
      winningCriteria: '',
      summary: '',
      startTime: moment().startOf('day').toDate().getTime(),
      endTime: moment().add(1, 'd').endOf('day').toDate().getTime(),
      instagram: '',
      twitter: '',
      tiktok: '',
      website: '',
      weightIG: 1,
      weightIGLikes: 1,
      weightIGComments: 1,
      weightTW: 1,
      weightTWLikes: 1,
      weightTWReplies: 1,
      weightTWRetweets: 1,
    };
  }, [campaign]);

  const onSubmit = async ({
    weightIG,
    weightIGLikes,
    weightIGComments,
    weightTW,
    weightTWLikes,
    weightTWReplies,
    weightTWRetweets,
    isPublic = true,
    ...values
  }) => {
    if (isPublic && !image && !values.image) {
      alert('Please upload a campaign image!');
      return;
    }
    setIsLoading(true);
    try {
      const formulaWeights = {};
      if (formulaPlatforms.instagram) {
        formulaWeights.instagram = {
          main: weightIG ?? 0,
          likes: weightIGLikes ?? 0,
          comments: weightIGComments ?? 0,
        };
      }
      if (formulaPlatforms.twitter) {
        formulaWeights.twitter = {
          main: weightTW ?? 0,
          likes: weightTWLikes ?? 0,
          replies: weightTWReplies ?? 0,
          retweets: weightTWRetweets ?? 0,
        };
      }

      const data = {
        ...values,
        formulaWeights,
        isPublic,
        startTime: Timestamp.fromDate(getTimeAsUTCDate(values.startTime)),
        endTime: Timestamp.fromDate(getTimeAsUTCDate(values.endTime)),
      };
      console.log({ data });

      if ((image && data.imageRef) || (data.imageRef && !data.image)) {
        const fileRef = ref(storage, data.imageRef);
        await deleteObject(fileRef);
        data.image = '';
        data.imageRef = '';
      }

      if (image) {
        const { imageRef, url } = await uploadImage(image);
        data.imageRef = imageRef;
        data.image = url;
      }

      /**
       * Logic detail: https://docs.google.com/document/d/1qe-JSwvCDM8iFS7HRZqtNFhOXI-mcscbsFxBPMtLSaY/edit
       * */
      if (id) {
        const isPublishingCampaign = data.isPublic !== campaign.isPublic;

        if (isPublishingCampaign) {
          const campaignId = await createCampaignWeb3(
            data.holdersBounty,
            data.nonHoldersBounty,
            data.maxRewardPerHolderWinner,
            data.maxRewardPerNonHolderWinner
          );
          await createCampaign({ ...data, id: campaignId });
          await removeCampaign(id);
        } else {
          await updateCampaign({ id, ...data });
        }
      } else {
        let campaignId;
        // draft campaigns are created without custom id --> will be deleted later
        if (isPublic)
          campaignId = await createCampaignWeb3(
            data.holdersBounty,
            data.nonHoldersBounty,
            data.maxRewardPerHolderWinner,
            data.maxRewardPerNonHolderWinner
          );
        await createCampaign({ ...data, id: campaignId });
      }

      enqueueSnackbar(
        `${id ? 'Updated' : 'Created'} ${
          !isPublic ? 'draft ' : ''
        }campaign successfully`,
        {
          variant: 'success',
        }
      );
      navigate('/campaigns');
    } catch (err) {
      console.error(err);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
    setIsLoading(false);
  };

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setTouched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  console.log({initialValues});

  const handleInputChange = (e) => {
    setImage(e.target.files[0]);
    e.target.value = '';
  };

  return (
    <Layout>
      <Loading isLoading={isLoading} />
      <ListLayout>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton onClick={() => navigate(`/campaigns`)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6">
              {!!id ? 'Update campaign' : 'Create campaign'}
            </Typography>
            {!!id && (
              <Button onClick={() => navigate(`/campaigns/${id}/participants`)}>
                View participants
              </Button>
            )}
          </Box>
          <Box display="flex" gap={4}>
            <Box display="flex" flexDirection="column" gap={2}>
              <label
                ref={labelRef}
                htmlFor={inputId}
                style={{ display: 'none' }}
              />
              <input
                id={inputId}
                type="file"
                accept="image/*"
                onChange={handleInputChange}
                style={{ display: 'none' }}
              />
              <img
                src={
                  image
                    ? URL.createObjectURL(image)
                    : values.image || '/images/placeholder.jpeg'
                }
                alt="avt"
                style={{
                  width: 280,
                  height: 280,
                  objectFit: 'cover',
                  objectPosition: 'center',
                  borderRadius: 8,
                }}
              />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={1}
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => labelRef.current?.click()}
                  sx={{ fontSize: 12 }}
                >
                  {image ? 'Change' : 'Upload'} image
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => {
                    setFieldValue('image', '');
                    setImage(null);
                  }}
                  sx={{ fontSize: 12 }}
                >
                  Remove
                </Button>
              </Box>
            </Box>
            <Box flex="1">
              <Input
                label="Campaign Title:"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur('name')}
                error={touched.name && errors.name}
                helperText={errors.name}
              />
              <Box display="flex" alignItems="center" gap={2}>
                <Box flex={1}>
                  <DatePicker
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={new Date(values.startTime)}
                    onChange={(date) => {
                      setTouched({ ...touched, startTime: true });
                      setFieldValue('startTime', date.getTime());
                    }}
                    customInput={
                      <Input
                        fullWidth
                        variant="outlined"
                        label="Start at:"
                        error={touched.startTime && errors.startTime}
                        helperText={errors.startTime}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              GMT+0
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </Box>
                <Box flex={1}>
                  <DatePicker
                    showTimeSelect
                    dateFormat="yyyy/MM/dd HH:mm"
                    timeFormat="HH:mm"
                    selected={new Date(values.endTime)}
                    onChange={(date) => {
                      setTouched({ ...touched, endTime: true });
                      setFieldValue('endTime', date.getTime());
                    }}
                    customInput={
                      <Input
                        fullWidth
                        variant="outlined"
                        label="End at:"
                        error={touched.endTime && errors.endTime}
                        helperText={errors.endTime}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              GMT+0
                            </InputAdornment>
                          ),
                        }}
                      />
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <FieldLabel>Bounty:</FieldLabel>
            <Box
              border="1px solid rgba(0, 0, 0, 0.23)"
              borderRadius={0.5}
              pt={3}
              px={3}
            >
              <Box display="flex" borderRadius={0.5}>
                <Tooltip
                  title="Total reward for $MIRL token holders"
                  arrow
                  placement="top"
                >
                  <Typography
                    fontStyle="italic"
                    fontWeight="bold"
                    sx={{ width: '7em' }}
                  >
                    $MIRL holders:{' '}
                  </Typography>
                </Tooltip>
                <Tooltip
                  title={
                    campaign?.isPublic
                      ? 'Cannot edit amount after published'
                      : 'Amount'
                  }
                  arrow
                  placement="top"
                >
                  <TextField
                    disabled={campaign?.isPublic}
                    size="small"
                    variant="standard"
                    type="number"
                    name="holdersBounty"
                    value={values.holdersBounty || ''}
                    onChange={(e) =>
                      setFieldValue(
                        'holdersBounty',
                        Number(e.target.value || '0')
                      )
                    }
                    onBlur={handleBlur('holdersBounty')}
                    error={Boolean(
                      touched.holdersBounty && errors.holdersBounty
                    )}
                    helperText={errors.holdersBounty ?? ' '}
                    sx={{ fontStyle: 'italic', mx: 1, width: 100 }}
                  />
                </Tooltip>
                <Typography fontStyle="italic">
                  $MIRL total, capped at
                </Typography>
                <TextField
                  disabled={campaign?.isPublic}
                  size="small"
                  variant="standard"
                  type="number"
                  name="maxRewardPerHolderWinner"
                  value={values.maxRewardPerHolderWinner || ''}
                  onChange={(e) =>
                    setFieldValue(
                      'maxRewardPerHolderWinner',
                      Number(e.target.value || '0')
                    )
                  }
                  onBlur={handleBlur}
                  error={Boolean(
                    touched.maxRewardPerHolderWinner &&
                      errors.maxRewardPerHolderWinner
                  )}
                  helperText={errors.maxRewardPerHolderWinner ?? ' '}
                  sx={{
                    fontStyle: 'italic',
                    px: 1,
                    width: 70,
                    fontSize: 14,
                  }}
                />
                <Typography fontStyle="italic">per pax.</Typography>
              </Box>
              <Box display="flex" borderRadius={0.5}>
                <Tooltip
                  title="Total reward for wallets with no $MIRL"
                  arrow
                  placement="top"
                >
                  <Typography
                    fontStyle="italic"
                    fontWeight="bold"
                    sx={{ width: '7em' }}
                  >
                    Non-holders:{' '}
                  </Typography>
                </Tooltip>
                <Tooltip
                  title={
                    campaign?.isPublic
                      ? 'Cannot edit amount after published'
                      : 'Amount'
                  }
                  arrow
                  placement="top"
                >
                  <TextField
                    disabled={campaign?.isPublic}
                    size="small"
                    variant="standard"
                    type="number"
                    name="nonHoldersBounty"
                    value={values.nonHoldersBounty || ''}
                    onChange={(e) =>
                      setFieldValue(
                        'nonHoldersBounty',
                        Number(e.target.value || '0')
                      )
                    }
                    onBlur={handleBlur('nonHoldersBounty')}
                    error={Boolean(
                      touched.nonHoldersBounty && errors.nonHoldersBounty
                    )}
                    helperText={errors.nonHoldersBounty ?? ' '}
                    sx={{ fontStyle: 'italic', mx: 1, width: 100 }}
                  />
                </Tooltip>
                <Typography fontStyle="italic">
                  $MIRL total, capped at
                </Typography>
                <Tooltip
                  title={
                    campaign?.isPublic ? 'Cannot edit after published' : ''
                  }
                  arrow
                  placement="top"
                >
                  <TextField
                    disabled={campaign?.isPublic}
                    size="small"
                    variant="standard"
                    type="number"
                    name="maxRewardPerNonHolderWinner"
                    value={values.maxRewardPerNonHolderWinner || ''}
                    onChange={(e) =>
                      setFieldValue(
                        'maxRewardPerNonHolderWinner',
                        Number(e.target.value || '0')
                      )
                    }
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.maxRewardPerNonHolderWinner &&
                        errors.maxRewardPerNonHolderWinner
                    )}
                    helperText={errors.maxRewardPerNonHolderWinner ?? ' '}
                    sx={{
                      fontStyle: 'italic',
                      px: 1,
                      width: 70,
                      fontSize: 14,
                    }}
                  />
                </Tooltip>
                <Typography fontStyle="italic">per pax.</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <FieldLabel>Campaign Socials:</FieldLabel>
            <Box display="flex" justifyContent="space-between" gap={2}>
              <TextField
                fullWidth
                name="twitter"
                value={values.twitter || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.twitter && errors.twitter)}
                helperText={errors.twitter ?? ' '}
                placeholder="Twitter link"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TwitterIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                name="instagram"
                value={values.instagram || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.instagram && errors.instagram)}
                helperText={errors.instagram ?? ' '}
                placeholder="Instagram link"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <InstagramIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                name="website"
                value={values.website || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(touched.website && errors.website)}
                helperText={errors.website ?? ' '}
                placeholder="Website link"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LanguageIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <HtmlInput
            label="Description:"
            value={values.description}
            onChange={(value) => {
              setFieldValue('description', value);
              setTouched({ ...touched, description: true });
            }}
            error={touched.description && errors.description}
            helperText={errors.description}
          />
          <HtmlInput
            label="Task & Deliverables:"
            value={values.task}
            onChange={(value) => {
              setFieldValue('task', value);
              setTouched({ ...touched, task: true });
            }}
            error={touched.task && errors.task}
            helperText={errors.task}
          />
          <Input
            label="Task Summary"
            name="taskShort"
            placeholder="1x IG post"
            value={values.taskShort || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.taskShort && errors.taskShort}
            helperText={errors.taskShort}
          />
          <HtmlInput
            label="Winning Criteria:"
            value={values.winningCriteria}
            onChange={(value) => {
              setFieldValue('winningCriteria', value);
              setTouched({ ...touched, winningCriteria: true });
            }}
            error={touched.winningCriteria && errors.winningCriteria}
            helperText={errors.winningCriteria}
          />
          <Box>
            <Box display="flex" gap={5}>
              <FieldLabel>Popularity Score Formula:</FieldLabel>
              <Box display="flex" gap={3}>
                <Button
                  startIcon={
                    formulaPlatforms.instagram ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )
                  }
                  onClick={() =>
                    formulaPlatforms.twitter &&
                    setFormulaPlatforms((current) => ({
                      ...current,
                      instagram: !current.instagram,
                    }))
                  }
                >
                  Instagram
                </Button>
                <Button
                  startIcon={
                    formulaPlatforms.twitter ? (
                      <CheckBoxIcon />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )
                  }
                  onClick={() =>
                    formulaPlatforms.instagram &&
                    setFormulaPlatforms((current) => ({
                      ...current,
                      twitter: !current.twitter,
                    }))
                  }
                >
                  Twitter
                </Button>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              border="1px solid rgba(0, 0, 0, 0.23)"
              borderRadius={0.5}
              p={2}
              pb={0}
            >
              <Typography fontStyle="italic">score =</Typography>
              <Typography
                fontStyle="italic"
                color={formulaPlatforms.instagram ? 'black' : 'grey'}
                sx={{ ml: 0.5 }}
              >
                (IG likes x{' '}
              </Typography>
              <TextField
                disabled={!formulaPlatforms.instagram}
                size="small"
                variant="standard"
                type="number"
                name="weightIGLikes"
                value={values.weightIGLikes || ''}
                onChange={(e) =>
                  setFieldValue('weightIGLikes', Number(e.target.value || '0'))
                }
                onBlur={handleBlur('weightIGLikes')}
                error={Boolean(touched.weightIGLikes && errors.weightIGLikes)}
                helperText={errors.weightIGLikes ?? ' '}
                sx={{
                  fontStyle: 'italic',
                  mx: 0.5,
                  width: 32,
                }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                }}
              />
              <Typography
                fontStyle="italic"
                color={formulaPlatforms.instagram ? 'black' : 'grey'}
              >
                + IG comments x{' '}
              </Typography>
              <TextField
                disabled={!formulaPlatforms.instagram}
                size="small"
                variant="standard"
                type="number"
                name="weightIGComments"
                value={values.weightIGComments || ''}
                onChange={(e) =>
                  setFieldValue(
                    'weightIGComments',
                    Number(e.target.value || '0')
                  )
                }
                onBlur={handleBlur('weightIGComments')}
                error={Boolean(
                  touched.weightIGComments && errors.weightIGComments
                )}
                helperText={errors.weightIGComments ?? ' '}
                sx={{
                  fontStyle: 'italic',
                  mx: 0.5,
                  width: 32,
                }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                }}
              />
              <Typography
                fontStyle="italic"
                color={formulaPlatforms.instagram ? 'black' : 'grey'}
              >
                ) x{' '}
              </Typography>{' '}
              <TextField
                disabled={!formulaPlatforms.instagram}
                size="small"
                variant="standard"
                type="number"
                name="weightIG"
                value={values.weightIG || ''}
                onChange={(e) =>
                  setFieldValue('weightIG', Number(e.target.value || '0'))
                }
                onBlur={handleBlur('weightIG')}
                error={Boolean(touched.weightIG && errors.weightIG)}
                helperText={errors.weightIG ?? ' '}
                sx={{
                  fontStyle: 'italic',
                  mx: 0.5,
                  width: 32,
                }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                }}
              />
              <Typography fontStyle="italic"> + </Typography>
              <Typography
                fontStyle="italic"
                color={formulaPlatforms.twitter ? 'black' : 'grey'}
                sx={{ ml: 0.5 }}
              >
                (TW likes x{' '}
              </Typography>
              <TextField
                disabled={!formulaPlatforms.twitter}
                size="small"
                variant="standard"
                type="number"
                name="weightTWLikes"
                value={values.weightTWLikes || ''}
                onChange={(e) =>
                  setFieldValue('weightTWLikes', Number(e.target.value || '0'))
                }
                onBlur={handleBlur('weightTWLikes')}
                error={Boolean(touched.weightTWLikes && errors.weightTWLikes)}
                helperText={errors.weightTWLikes ?? ' '}
                sx={{
                  fontStyle: 'italic',
                  mx: 0.5,
                  width: 32,
                }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                }}
              />
              <Typography
                fontStyle="italic"
                color={formulaPlatforms.twitter ? 'black' : 'grey'}
              >
                {' '}
                + TW replies x{' '}
              </Typography>{' '}
              <TextField
                disabled={!formulaPlatforms.twitter}
                size="small"
                variant="standard"
                type="number"
                name="weightTWReplies"
                value={values.weightTWReplies || ''}
                onChange={(e) =>
                  setFieldValue(
                    'weightTWReplies',
                    Number(e.target.value || '0')
                  )
                }
                onBlur={handleBlur('weightTWReplies')}
                error={Boolean(
                  touched.weightTWReplies && errors.weightTWReplies
                )}
                helperText={errors.weightTWReplies ?? ' '}
                sx={{
                  fontStyle: 'italic',
                  mx: 0.5,
                  width: 32,
                }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                }}
              />
              <Typography
                fontStyle="italic"
                color={formulaPlatforms.twitter ? 'black' : 'grey'}
              >
                {' '}
                + TW retweets x{' '}
              </Typography>{' '}
              <TextField
                disabled={!formulaPlatforms.twitter}
                size="small"
                variant="standard"
                type="number"
                name="weightTWRetweets"
                value={values.weightTWRetweets || ''}
                onChange={(e) =>
                  setFieldValue(
                    'weightTWRetweets',
                    Number(e.target.value || '0')
                  )
                }
                onBlur={handleBlur('weightTWRetweets')}
                error={Boolean(
                  touched.weightTWRetweets && errors.weightTWRetweets
                )}
                helperText={errors.weightTWRetweets ?? ' '}
                sx={{
                  fontStyle: 'italic',
                  mx: 0.5,
                  width: 32,
                }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                }}
              />
              <Typography
                fontStyle="italic"
                color={formulaPlatforms.twitter ? 'black' : 'grey'}
              >
                ) x{' '}
              </Typography>
              <TextField
                disabled={!formulaPlatforms.twitter}
                size="small"
                variant="standard"
                type="number"
                name="weightTW"
                value={values.weightTW || ''}
                onChange={(e) =>
                  setFieldValue('weightTW', Number(e.target.value || '0'))
                }
                onBlur={handleBlur('weightTW')}
                error={Boolean(touched.weightTW && errors.weightTW)}
                helperText={errors.weightTW ?? ' '}
                sx={{
                  fontStyle: 'italic',
                  mx: 0.5,
                  width: 32,
                }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'center' },
                  },
                }}
              />
            </Box>
          </Box>

          <HtmlInput
            label="Summary"
            value={values.summary}
            onChange={(value) => {
              setFieldValue('summary', value);
              setTouched({ ...touched, summary: true });
            }}
            error={touched.summary && errors.summary}
            helperText={errors.summary}
          />
          <Box display="flex" justifyContent="space-around">
            {!campaign?.isPublic && (
              <Button
                variant="outlined"
                onClick={(e) => onSubmit({ ...values, isPublic: false })}
              >
                Save as draft
              </Button>
            )}
            <Button variant="contained" onClick={handleSubmit}>
              {!id
                ? 'Create'
                : campaign?.isPublic === false
                ? 'Publish'
                : 'Update'}{' '}
              campaign
            </Button>
          </Box>
        </Box>
      </ListLayout>
    </Layout>
  );
};

export default CampaignDetail;
